import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { CircularProgress, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function formatDate(isoString) {
  const date = new Date(isoString);
  return isoString ? date.toLocaleDateString() : ''; // Devuelve 'N/A' si no hay fecha
}

const UserTerminals = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Función para obtener datos, memoizada con useCallback
  const fetchData = useCallback(async () => {
    const perfilId = localStorage.getItem('perfilId');
    const groupId = localStorage.getItem('groupId');
    const userId = localStorage.getItem('userId');

    let apiEndpoint;

    if (perfilId === '1' || perfilId === '2') {
      apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling';
    } else if (perfilId === '3') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling/group/${groupId}`;
    } else if (perfilId === '4') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling/user/${userId}`;
    }

    try {
      const response = await axios.get(apiEndpoint, {
        headers: {
          accept: 'application/json',
        },
      });

      // Mapear todos los campos del JSON, incluso si están en null
      const mappedRows = response.data.map((item, index) => ({
        id: index,
        serviceLineNumber: item.serviceLineNumber || 'N/A',
        addressReferenceId: item.addressReferenceId || 'N/A',
        nickname: item.nickname || item.serviceLineNumber,
        productReferenceId: item.productReferenceId || 'N/A',
        delayedProductId: item.delayedProductId || 'N/A',
        optInProductId: item.optInProductId || 'N/A',
        serviceStartDate: item.serviceStartDate || 'N/A',
        serviceEndDate: item.serviceEndDate || '',
        publicIp: item.publicIp === 1 ? 'Yes' : 'No',
        serviceActive: item.serviceActive === 1 ? 'Active' : 'Inactive',
        IdClient: item.IdClient || 'N/A',
        accountNumber: item.accountNumber || 'N/A',
        billStartDate: item.billStartDate || 'N/A',
        billEndDate: item.billEndDate || 'N/A',
        isoCurrencyCode: item.isoCurrencyCode || 'N/A',
        isMobilePlan: item.isMobilePlan === 1 ? 'Yes' : 'No',
        billActiveFrom: item.billActiveFrom || 'N/A',
        subscriptionActiveFrom: item.subscriptionActiveFrom || 'N/A',
        subscriptionEndDate: item.subscriptionEndDate || 'N/A',
        overageName: item.overageName || 'N/A',
        overageDescription: item.overageDescription || 'N/A',
        isOptedIntoOverage: item.isOptedIntoOverage === 1 ? 'Yes' : 'No',
      }));

      setRows(mappedRows);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user terminals:', error);
      setLoading(false);
    }
  }, []); // Dependencias vacías ya que no dependemos de variables externas

  // useEffect para obtener datos inicialmente y configurar el intervalo
  useEffect(() => {
    fetchData(); // Obtiene los datos inicialmente

    const intervalId = setInterval(() => {
      fetchData();
    }, 20000); // Actualiza cada 20 segundos

    // Limpia el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [fetchData]);

  // Definir las columnas para el DataGrid
  const columns = [
    {
      field: 'nickname',
      headerName: 'Service Line Number',
      width: 220,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() =>
            navigate(`/datadashboard`, {
              state: { serviceLineNumber: params.row.serviceLineNumber },
            })
          }
          sx={{
            width: '100%',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            textAlign: 'center',
          }}
        >
          {params.row.nickname}
        </Button>
      ),
    },
    {
      field: 'serviceLineNumber',
      headerName: 'Service Line Number',
      width: 180,
      renderCell: (params) => (
        <span style={{ fontWeight: 'bold' }}>{params.value}</span>
      ),
    },
    { field: 'productReferenceId', headerName: 'Product Reference ID', width: 250 },
    {
      field: 'serviceStartDate',
      headerName: 'Service Start Date',
      width: 200,
      renderCell: (params) => (
        <span>{formatDate(params.row.serviceStartDate)}</span>
      ),
    },
    {
      field: 'serviceEndDate',
      headerName: 'Service End Date',
      width: 200,
      renderCell: (params) => (
        <span>{formatDate(params.row.serviceEndDate)}</span>
      ),
    },
    { field: 'isMobilePlan', headerName: 'Mobile Plan', width: 120 },
    { field: 'publicIp', headerName: 'Public IP', width: 110 },
    { field: 'serviceActive', headerName: 'Active', width: 110 },
    
    { field: 'isoCurrencyCode', headerName: 'Currency Code', width: 150 },
    
    {
      field: 'billActiveFrom',
      headerName: 'Bill Active From',
      width: 200,
      renderCell: (params) => (
        <span>{formatDate(params.row.billActiveFrom)}</span>
      ),
    },
    {
      field: 'subscriptionActiveFrom',
      headerName: 'Subscription Active From',
      width: 200,
      renderCell: (params) => (
        <span>{formatDate(params.row.subscriptionActiveFrom)}</span>
      ),
    },
    { field: 'accountNumber', headerName: 'Account Number', width: 150 },
 
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '90vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowHeight={80}
        sx={{ border: 0 }}
      />
    </Box>
  );
};

export default UserTerminals;
