import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import * as h3 from 'h3-js';

/* global BigInt */

mapboxgl.accessToken = 'pk.eyJ1IjoiZnJvY2NvYXIiLCJhIjoiY20wbXo4N3FvMDMwbzJrb21kOXhsY3FlbyJ9.chVdQIlwUlzcsAvuJsbx8g';

const Maps = ({ devices = [] }) => {
  const theme = useTheme();
  const mapContainerRef = useRef(null);
  const mapInstance = useRef(null);
  const [isMapReady, setIsMapReady] = useState(false);
  const [userLocation, setUserLocation] = useState(null);


  const defaultMapDark = 'mapbox://styles/froccoar/claa813s2001115ns0dfdfzzt';
  const defaultMapLight = 'mapbox://styles/froccoar/clzru7x0100fn01pbf08t4v1v';

  const mapStyle = theme.palette.mode === 'dark' ? defaultMapDark : defaultMapLight;

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([longitude, latitude]);
      },
      (error) => {
        console.error(error);
        // If the user denies permission or an error occurs, use default location
        setUserLocation([-67.56045749329654, -24.05252433841831]); // Default coordinates
      }
    );
  }, []);

  useEffect(() => {
    if (!mapInstance.current) {
      // Inicializa el mapa
      mapInstance.current = new mapboxgl.Map({
        container: mapContainerRef.current, // ID del contenedor
        style: mapStyle,
        center: [-67.56045749329654, -24.05252433841831],
        zoom: 5,
        pitch: 0,
        bearing: 0,
      });

      mapInstance.current.addControl(new mapboxgl.NavigationControl());

      mapInstance.current.on('load', () => {
        setIsMapReady(true); // Marca el mapa como listo
      });
    }
  }, []);

  useEffect(() => {
    if (mapInstance.current && isMapReady) {
      // Actualiza el estilo del mapa cuando cambia el tema
      mapInstance.current.setStyle(mapStyle);

      // Esperar a que el nuevo estilo se cargue completamente antes de volver a agregar capas
      mapInstance.current.on('styledata', () => {
        devices.forEach((device) => {
          if (!device.ut_H3CellId) return;

          const H3CellIdHex = BigInt(device.ut_H3CellId).toString(16);
          if (!h3.isValidCell(H3CellIdHex)) return;

          const hexBoundary = h3.cellToBoundary(H3CellIdHex);
          const geoJsonPolygon = {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [hexBoundary.map((coord) => [coord[1], coord[0]])],
            },
            properties: {
              nickname: device.sli_nickname,
              serviceLineNumber: device.sli_serviceLineNumber,
              ClientName: device.ca_ClientName,
              isOnline: device.isOnline,
            },
          };

          if (mapInstance.current.getSource(`device-${device.uti_userTerminalId}`)) {
            mapInstance.current
              .getSource(`device-${device.uti_userTerminalId}`)
              .setData(geoJsonPolygon);
          } else {
            mapInstance.current.addSource(`device-${device.uti_userTerminalId}`, {
              type: 'geojson',
              data: geoJsonPolygon,
            });

            mapInstance.current.addLayer({
              id: `device-layer-${device.uti_userTerminalId}`,
              type: 'fill',
              source: `device-${device.uti_userTerminalId}`,
              paint: {
                'fill-color': ['case', ['==', ['get', 'isOnline'], true], '#00FF00', '#FF0000'],
                'fill-opacity': 0.8,
              },
            });
          }
        });
      });
    }
  }, [mapStyle, isMapReady, devices]); // Se ejecuta cuando cambia el estilo o los dispositivos

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
};

export default Maps;
