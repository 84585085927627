import React, { useEffect, useState, useCallback } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const Alerts = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Obtener el userId desde localStorage
  const userId = localStorage.getItem('userId');

  // Generar la fecha actual en formato YYYY-MM-DD
  const date = new Date().toISOString().split('T')[0];

  const fetchAlertData = useCallback(() => {
    axios
      .get(`https://echo.tsmpatagonia.com.ar:3001/api/stAlertDataUser/${userId}`, {
        params: { date }, // Pasar la fecha como parámetro
      })
      .then(response => {
        const data = response.data.map((item, index) => ({
          id: index,
          DeviceId: item.DeviceId,
          KitSerialNumber: item.KitSerialNumber,
          Code: item.Code,
          Error_Key: item.Error_Key,
          Descripcion: item.Descripcion,
          dishSerialNumber: item.dishSerialNumber,
          serviceLineNumber: item.serviceLineNumber,
          active: item.active ? 'Yes' : 'No',
          IdClient: item.IdClient,
          groupId: item.groupId,
          status: item.status,
          service: item.service,
          Primera_Aparicion: item.Primera_Aparicion,
          Ultima_Aparicion: item.Ultima_Aparicion,
        }));
        setRows(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching alert data:', error);
        setLoading(false);
      });
  }, [userId, date]);

  useEffect(() => {
    fetchAlertData();
  }, [fetchAlertData]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Define DataGrid columns with Kit Serial Number column as a button to navigate to dashboard
  const columns = [
   
    {
      field: 'KitSerialNumber',
      headerName: 'Kit Serial Number',
      width: 230,
      headerClassName: 'MuiDataGrid-columnHeaders',
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/dashboard`, { state: { deviceId: params.row.DeviceId.slice(2) } })}
          sx={{
            width: '100%',
            whiteSpace: 'normal',  // Allows text to wrap
            wordWrap: 'break-word',  // Breaks long words to wrap properly
            textAlign: 'center', // Center the text if needed
          }}
        >
          {params.row.KitSerialNumber}
        </Button>
      ),
    },
    {
      field: 'DeviceId',
      headerName: 'Device ID',
      width: 150,
      headerClassName: 'MuiDataGrid-columnHeaders'
    },
    { field: 'Code', headerName: 'Code', width: 50, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'Descripcion', headerName: 'Description', width: 300, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'dishSerialNumber', headerName: 'Dish Serial Number', width: 150, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'serviceLineNumber', headerName: 'Service Line Number', width: 150, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'Primera_Aparicion', headerName: 'First Appearance', width: 180, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'Ultima_Aparicion', headerName: 'Last Appearance', width: 180, headerClassName: 'MuiDataGrid-columnHeaders' },
  ];

  return (
    <Box sx={{ height: '90vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowHeight={80}
        sx={{
          border: 0,
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark : theme.palette.light,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.DataHeaders.dark : theme.palette.DataHeaders.light,
          },
        }}
      />
    </Box>
  );
};

export default Alerts;
