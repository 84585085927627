import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, Paper, Grid } from '@mui/material';
import { useMediaQuery, MenuItem, Select, FormControl, InputLabel, Stack, Button } from '@mui/material';

import { PieChart } from '@mui/x-charts/PieChart';
import Maps from './Maps';
import axios from 'axios';

/* global BigInt */


const checkOnlineStatus = (utcTimestampNs) => {
  const timestampMs = utcTimestampNs / 1e6;
  const buenosAiresTimeString = new Date(timestampMs).toLocaleString("en-US", {
    timeZone: "America/Argentina/Buenos_Aires",
    hour12: false,
  });

  const buenosAiresDate = new Date(buenosAiresTimeString);
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "America/Argentina/Buenos_Aires",
    hour12: false,
  });

  const currentDateObj = new Date(currentDate);
  const timeDifference = Math.abs(currentDateObj - buenosAiresDate);
  return timeDifference < 600000; // 10 minutos
};

const Home = () => {
  const [activeAlertsCount, setActiveAlertsCount] = useState(0);
  const [totalDevicesCount, setTotalDevicesCount] = useState(0);
  const [onlineDevicesCount, setOnlineDevicesCount] = useState(0);
  const [devices, setDevices] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const isSmallScreen = useMediaQuery('(max-width:600px)');


  const fetchAlertsData = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const date = new Date().toISOString().split('T')[0];
      const alertsResponse = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/stAlertDataUser/${userId}`, {
        params: { date },
      });
      setActiveAlertsCount(alertsResponse.data.length);
    } catch (error) {
      console.error('Error fetching alerts data:', error);
    }
  };

  const fetchDevicesData = async () => {
    try {
      const perfilId = localStorage.getItem('perfilId');
      const groupId = localStorage.getItem('groupId');
      const userId = localStorage.getItem('userId');

      let apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters';
      if (perfilId === '3') apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/group/${groupId}`;
      else if (perfilId === '4') apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/user/${userId}`;

      const devicesResponse = await axios.get(apiEndpoint);
      const fetchedDevices = devicesResponse.data.map((device) => ({
        ...device,
        isOnline: checkOnlineStatus(device.ut_UtcTimestampNs),
      }));

      setDevices((prevDevices) => {
        if (JSON.stringify(fetchedDevices) !== JSON.stringify(prevDevices)) {
          setTotalDevicesCount(fetchedDevices.length);
          const onlineDevices = fetchedDevices.filter((device) => device.isOnline);
          setOnlineDevicesCount(onlineDevices.length);
          return fetchedDevices;
        }
        return prevDevices;
      });

      setInitialLoading(false);
    } catch (error) {
      console.error('Error fetching devices data:', error);
    }
  };

  useEffect(() => {
    fetchAlertsData();
    fetchDevicesData();

    const interval = setInterval(fetchDevicesData, 15000);

    return () => clearInterval(interval);
  }, []);

  if (initialLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3, width: '100%', height: '90vh' }}>
  <Grid container spacing={3} sx={{ height: '100%' }}>
    {/* Columna Izquierda */}
    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
      {/* Alerta Activa */}
      <Paper elevation={3} sx={{ padding: 2, textAlign: 'center', flex: '0 0 20%' }}>
        <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>
          Active Alerts</Typography>
        <Typography variant={isSmallScreen ? "body2" : "h1"} sx={{ color: 'text.secondary', fontWeight: 'bold', mx: 'auto' }}>
          {activeAlertsCount}
        </Typography>
      </Paper>

      {/* Resumen de Dispositivos */}
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          textAlign: 'center',
          flex: '1 1 60%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold', mb: 2 }}>
          Devices Overview
        </Typography>
        <PieChart
          series={[
            {
              data: [
                { id: 'Online', value: onlineDevicesCount, label: 'Online'},
                { id: 'Offline', value: totalDevicesCount - onlineDevicesCount , label: 'Offline'},
              ],
              highlightScope: { fade: 'global', highlight: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              
              innerRadius: 50,
            },
          ]}
          slotProps={{
            legend: { hidden: true },
          }}
          width={200}
          height={200}
          margin={{
            left: 23,
            right: 30,
            top: 30,
            bottom: 30,
          }}
          sx = {{mx: 'auto',}}
        />
        {/* Información de Dispositivos */}
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>
              Online Devices
            </Typography>
            <Typography variant={isSmallScreen ? "body2" : "h3"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
              {onlineDevicesCount}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant={isSmallScreen ? "body2" : "h6"} sx={{ fontWeight: 'bold' }}>
              Total Devices
            </Typography>
            <Typography variant={isSmallScreen ? "body2" : "h3"} sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
              {totalDevicesCount}
            </Typography>
          </Box>
        </Box>
      </Paper>
      
    </Grid>

    {/* Columna Derecha */}
    <Grid item xs={12} md={8} sx={{ height: '100%' }}>
      <Paper elevation={3} sx={{ height: '100%', overflow: 'hidden' }}>
        <Maps devices={devices} />
      </Paper>
    </Grid>
  </Grid>
</Box>


  );
};

export default Home;
