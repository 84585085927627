import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Tabs,
  Paper,
  Tab,
  Avatar,
  Grid,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import EditUser from './EditUser'; // Importa el nuevo componente EditUser


const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [passwordError, setPasswordError] = useState('');
  const [imageError, setImageError] = useState('');
  const [avatarPreview, setAvatarPreview] = useState(null);


  const token = localStorage.getItem('token');
  const perfilId = localStorage.getItem('perfilId');
  const groupId = localStorage.getItem('groupId');

  if (perfilId == '4') {
    return <Typography variant="h6">You are not authorized to create new users.</Typography>;
  }

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(password);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      if (value && !validatePassword(value)) {
        setPasswordError('Password must be at least 8 characters, include an uppercase letter and a number.');
      } else {
        setPasswordError('');
      }
    }
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
  
    if (!file) {
      setFormData({ ...formData, avatar: null });
      return;
    }
  
    // Check if the file is a JPG
    if (file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
      setImageError('Only JPG files are allowed.');
      return;
    }
  
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 800; // Adjust the maximum size
        const maxHeight = 800;
        let width = img.width;
        let height = img.height;
  
        // Calculate the new dimensions maintaining the ratio
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }
  
        // Adjust the canvas to the new size
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        // Convert the canvas to base64
        const resizedImage = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality if needed
        const base64String = resizedImage.split(',')[1]; // Remove the "data:image/jpeg;base64," prefix
  
        setFormData({ ...formData, avatar: base64String });
        setAvatarPreview(resizedImage); // Optional: Update the image preview
      };
    };
  
    reader.readAsDataURL(file); // Read the file as a data URL
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await axios.post('https://echo.tsmpatagonia.com.ar:3001/api/users', {
        nombre: formData.nombre,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        perfil_id: 4, // Default perfil_id for new users created by Partner is 4
        grupo_id: groupId, // Assign new user to the same group as the current user
        avatar: formData.avatar,
        
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSuccess('User created successfully');
      setFormData({
        nombre: '',
        lastName: '',
        email: '',
        password: '',
      });
    } catch (err) {
      setError(`Error creating user: ${err.response?.data?.error || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {imageError && <Alert severity="error">{imageError}</Alert>}

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} align="center">
          <Typography variant="h6" gutterBottom>
            Profile Picture
          </Typography>
          {avatarPreview ? (
            <Avatar src={avatarPreview} alt="Current avatar" sx={{ width: 150, height: 150, margin: 'auto' }} />
          ) : (
            <Avatar sx={{ width: 150, height: 150, margin: 'auto' }} />
          )}
          <Button component="label" sx={{ mt: 2 }} variant="contained">
            Change Photo (JPG Only)
            <input type="file" hidden onChange={handleFileChange} accept="image/jpeg" />
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: "0" }}>
            <Box component="form" onSubmit={handleFormSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="First Name"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                helperText={passwordError}
                error={!!passwordError}
                required
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                disabled={loading || !!passwordError}
              >
                {loading ? <CircularProgress size={24} /> : 'Create User'}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const UserManagementTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userTerminals, setUserTerminals] = useState([]);
  const [users, setUsers] = useState([]);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const perfilId = localStorage.getItem('perfilId');
  const groupId = localStorage.getItem('groupId');
  
  
  let apiEndpoint;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (perfilId === '1' || perfilId === '2') {
          apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIduser';
        } else if (perfilId === '3') {
          apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIduser/user/${userId}`;
        }
        const terminalsResponse = await axios.get(apiEndpoint);

        // Aquí asumimos que cada terminal tiene un campo 'associated_user_ids', que es un arreglo de IDs de usuarios asociados.
        // Si el campo en tu API es diferente, ajústalo aquí.
        
        const terminalsData = terminalsResponse.data.map((terminal) => {
          const associatedUserId = terminal.associated_user_id;
          let associated_user_ids = [];
        
          if (associatedUserId) {
            try {
              // Intentar parsear como JSON
              const parsed = JSON.parse(associatedUserId);
        
              if (Array.isArray(parsed)) {
                // Si es un arreglo, convertimos cada elemento a cadena
                associated_user_ids = parsed.map((id) => id.toString());
              } else {
                // Si es un valor único, lo convertimos a cadena y lo colocamos en un arreglo
                associated_user_ids = [parsed.toString()];
              }
            } catch (e) {
              // Si JSON.parse falla, dividimos la cadena por comas
              const ids = associatedUserId.split(',').map((id) => id.trim());
              associated_user_ids = ids;
            }
          }
        
          return {
            ...terminal,
            associated_user_ids,
          };
        });
        
        
        setUserTerminals(terminalsData);

        const usersResponse = await axios.get('https://echo.tsmpatagonia.com.ar:3001/api/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(usersResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [tabIndex, userId, token]);


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const columns = [
    { field: 'uti_kitSerialNumber', headerName: 'Kit Serial Number', width: 150, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'uti_serviceLineNumber', headerName: 'Service Line Number', width: 150, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'sli_nickname', headerName: 'Nickname', width: 250 , headerClassName: 'MuiDataGrid-columnHeaders'},
    {
      field: 'associated_user_ids',
      headerName: 'Associated Users',
      width: 250,
      headerClassName: 'MuiDataGrid-columnHeaders',
      renderCell: (params) => {
        const handleUserChange = async (e) => {
          const selectedUserIds = e.target.value; // Arreglo de IDs de usuarios seleccionados
          const deviceId = "ut" + params.row.uti_userTerminalId;
          
          // Convierte el arreglo de IDs a una cadena JSON
          const userId = JSON.stringify(selectedUserIds);
    
          try {
            // Realizar un PUT con userId como cadena que representa un arreglo
            console.log(userId);
            await axios.put('https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdUser/updateAssociatedUser', {
              deviceId,
              userId
            }, {
              headers: { Authorization: `Bearer ${token}` },
            });
    
            // Actualizar el estado local para reflejar los nuevos usuarios asociados
            setUserTerminals((prevTerminals) =>
              prevTerminals.map((terminal) =>
                terminal.uti_userTerminalId === params.row.uti_userTerminalId
                  ? { ...terminal, associated_user_ids: selectedUserIds }
                  : terminal
              )
            );
    
            console.log('Usuarios actualizados exitosamente:', selectedUserIds);
          } catch (error) {
            if (error.response) {
              console.error('Error response:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
            } else if (error.request) {
              console.error('No response received:', error.request);
            } else {
              console.error('Error setting up request:', error.message);
            }
          }
        };
    
        return (
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <Select
              multiple
              value={params.row.associated_user_ids || []}
              onChange={handleUserChange}
              renderValue={(selected) => {
                const selectedUsers = users.filter((user) => selected.includes(user.id.toString()));
                return selectedUsers.map((user) => `${user.nombre} ${user.lastName}`).join(', ');
              }}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id.toString()}>
                  {user.nombre} {user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    
    
    
    { field: 'uti_userTerminalId', headerName: 'User Terminal ID', width: 150, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'uti_active', headerName: 'Active', width: 50, headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'ca_AccountNumber', headerName: 'Account Number', width: 200 , headerClassName: 'MuiDataGrid-columnHeaders'},
    { field: 'ca_ClientName', headerName: 'Client Name', width: 200, headerClassName: 'MuiDataGrid-columnHeaders' },

  ];

  const fetchUserTerminals = async () => {
    try {
      const response = await axios.get(`https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdUser/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserTerminals(response.data);

        } catch (error) {
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Status:', error.response.status);
            console.error('Headers:', error.response.headers);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error setting up request:', error.message);
          }
        }
  };
  
  // Usar fetchUserTerminals cuando sea necesario
  useEffect(() => {
    fetchUserTerminals();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="user management tabs">
        <Tab label="Create New User" />
        <Tab label="Edit Users" />
        <Tab label="allocate UserTerminals" />
      </Tabs>
      <Box sx={{ padding: 3 }}>
        {tabIndex === 0 && <CreateUser />}
        {tabIndex === 1 && <EditUser />} {/* Renderiza el nuevo componente EditUser */}
        {tabIndex === 2 && (
          <Box sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={userTerminals}
              columns={columns}
              pageSize={10}
              owHeight={80}
              getRowId={(row) => row.uti_userTerminalId}
              sx={{
                border: 0,
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark : theme.palette.light,
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.DataHeaders.dark : theme.palette.DataHeaders.light,
                },
              }}/>
          </Box>
        )}
        
      </Box>
    </Box>
  );
};

export default UserManagementTabs;
