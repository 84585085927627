import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from '@mui/material';
import axios from 'axios';

const RoutersAdmin = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState(null); // State to manage which row is being edited
  const [newDeviceName, setNewDeviceName] = useState('');
  const [newUserId, setNewUserId] = useState('');

  // Fetch router data
  const fetchRouterData = () => {
    axios
      .get('https://echo.tsmpatagonia.com.ar:3001/api/devices')
      .then((response) => {
        const data = response.data.map((item, index) => ({
          id: index,
          DeviceId: item.DeviceId,
          DeviceName: item.DeviceName,
          userId: item.userId,
        }));
        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching router data:', error);
        setLoading(false);
      });
  };

  // Function to update device information
  const updateDeviceInfo = async (deviceId, newDeviceName, newUserId) => {
    try {
      const response = await fetch('https://echo.tsmpatagonia.com.ar:3001/api/devices/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ deviceId, newDeviceName, newUserId }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Update successful:', result);
        fetchRouterData(); // Refresh data after update
      } else {
        console.error('Error updating device:', await response.json());
      }
    } catch (error) {
      console.error('Error in updateDeviceInfo:', error);
    }
  };

  useEffect(() => {
    fetchRouterData(); // Initial fetch
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Typography variant="h4" gutterBottom>Routers Admin Panel</Typography>
      <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
        <Table stickyHeader aria-label="Routers admin table">
          <TableHead>
            <TableRow>
              <TableCell>Device ID</TableCell>
              <TableCell>Device Name</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.DeviceId}</TableCell>
                <TableCell>
                  {editingRow === row.DeviceId ? (
                    <TextField
                      value={newDeviceName}
                      onChange={(e) => setNewDeviceName(e.target.value)}
                      placeholder="Enter new device name"
                      size="small"
                    />
                  ) : (
                    row.DeviceName
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === row.DeviceId ? (
                    <TextField
                      value={newUserId}
                      onChange={(e) => setNewUserId(e.target.value)}
                      placeholder="Enter new user ID"
                      size="small"
                    />
                  ) : (
                    row.userId
                  )}
                </TableCell>
                <TableCell>
                  {editingRow === row.DeviceId ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateDeviceInfo(row.DeviceId, newDeviceName, newUserId);
                        setEditingRow(null); // Exit editing mode
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setEditingRow(row.DeviceId); // Enter editing mode
                        setNewDeviceName(row.DeviceName || ''); // Set initial values
                        setNewUserId(row.userId || '');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RoutersAdmin;
