import React, { useState, useEffect } from 'react';

import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Grid,
  Alert,
} from '@mui/material';
import axios from 'axios';

const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    nombre: '',
    lastName: '',
    email: '',
    password: '',
    perfil_id: '',
    grupo_id: '',
    avatar: null,
  });
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [imageError, setImageError] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://echo.tsmpatagonia.com.ar:3001/api/me', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userData = response.data;

      setFormData({
        ...userData,
        password: '', // Leave the password field empty initially
        avatar: null, // Clear the avatar field for new uploads
      });

      if (userData.avatar) {
        setAvatarPreview(`data:image/jpeg;base64,${userData.avatar}`);
      }
    } catch (err) {
      setError('Error loading user data');
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(password);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      if (value && !validatePassword(value)) {
        setPasswordError('Password must be at least 8 characters, include an uppercase letter and a number.');
      } else {
        setPasswordError('');
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
  
    if (!file) {
      setFormData({ ...formData, avatar: null });
      return;
    }
  
    // Check if the file is a JPG
    if (file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
      setImageError('Only JPG files are allowed.');
      return;
    }
  
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 800; // Adjust the maximum size
        const maxHeight = 800;
        let width = img.width;
        let height = img.height;
  
        // Calculate the new dimensions maintaining the ratio
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }
  
        // Adjust the canvas to the new size
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        // Convert the canvas to base64
        const resizedImage = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality if needed
        const base64String = resizedImage.split(',')[1]; // Remove the "data:image/jpeg;base64," prefix
  
        setFormData({ ...formData, avatar: base64String });
        setAvatarPreview(resizedImage); // Optional: Update the image preview
      };
    };
  
    reader.readAsDataURL(file); // Read the file as a data URL
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await axios.put('https://echo.tsmpatagonia.com.ar:3001/api/users', {
        id: formData.id,
        nombre: formData.nombre,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        perfil_id: formData.perfil_id,
        grupo_id: formData.grupo_id,
        avatar: formData.avatar,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      const { id, perfil_id, grupo_id, nombre, lastName, avatar } = response.data;

      localStorage.setItem('userId', id); // Save userId to localStorage
      localStorage.setItem('perfilId', perfil_id); // Save perfil_id to localStorage
      localStorage.setItem('groupId', grupo_id); // Save group_id to localStorage
      localStorage.setItem('name', nombre); // Save nombre to localStorage
      localStorage.setItem('lastName', lastName); // Save lastName to localStorage
  
      // If avatar was updated, save the new base64 URL to localStorage
      if (formData.avatar) {
        localStorage.setItem('avatar', formData.avatar);
      }

      setSuccess('Profile updated successfully');
      fetchUserData();
    } catch (err) {
      setError(`Error updating profile: ${err.response?.data?.error || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Edit Profile
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {imageError && <Alert severity="error">{imageError}</Alert>}

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} align="center">
          <Typography variant="h6" gutterBottom>
            Profile Picture
          </Typography>
          {avatarPreview ? (
            <Avatar src={avatarPreview} alt="Current avatar" sx={{ width: 150, height: 150, margin: 'auto' }} />
          ) : (
            <Avatar sx={{ width: 150, height: 150, margin: 'auto' }} />
          )}
          <Button component="label" sx={{ mt: 2 }} variant="contained">
            Change Photo (JPG Only)
            <input type="file" hidden onChange={handleFileChange} accept="image/jpeg" />
          </Button>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h6">Profile Information</Typography>
          <Box component="form" onSubmit={handleFormSubmit}>
            <TextField fullWidth margin="normal" label="ID" name="id" value={formData.id} />
            <TextField fullWidth margin="normal" label="Email" name="email" value={formData.email} />
            <TextField fullWidth margin="normal" label="First Name" name="nombre" value={formData.nombre} onChange={handleInputChange} required />
            <TextField fullWidth margin="normal" label="Last Name" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
            <TextField fullWidth margin="normal" label="New Password" name="password" type="password" value={formData.password} onChange={handleInputChange} helperText={passwordError} error={!!passwordError} />
            <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }} disabled={loading || !!passwordError || !!imageError}>
              {loading ? <CircularProgress size={24} /> : 'Update Profile'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProfile;
