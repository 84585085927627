import React, { useEffect, useState, useCallback } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

function formatUptime(seconds) {
  const days = Math.floor(seconds / (24 * 3600)); // Calculate total days
  const hours = Math.floor((seconds % (24 * 3600)) / 3600); // Calculate remaining hours
  const minutes = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
  const secs = seconds % 60; // Calculate remaining seconds

  return `${days}d:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

const Routers = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkOnlineStatus = (utcTimestampNs) => {
    const timestampMs = utcTimestampNs / 1e6;
    const buenosAiresTimeString = new Date(timestampMs).toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const buenosAiresDate = new Date(buenosAiresTimeString);
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const currentDateObj = new Date(currentDate);
    const timeDifference = Math.abs(currentDateObj - buenosAiresDate);
    return timeDifference < 600000; // 2 minutes
  };

  const fetchRouterData = useCallback(() => {
    const perfilId = localStorage.getItem('perfilId');
    const groupId = localStorage.getItem('groupId');
    const userId = localStorage.getItem('userId');
  
    let apiEndpoint;
  
    if (perfilId === '1' || perfilId === '2') {
      apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters';
    } else if (perfilId === '3') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/group/${groupId}`;
    } else if (perfilId === '4') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/user/${userId}`;
    }
  
    if (apiEndpoint) {
      axios.get(apiEndpoint)
        .then(response => {
          const data = response.data.map((item, index) => ({
            id: index,
            DeviceName: item.sli_nickname || item.uti_kitSerialNumber,
            Kitname: item.uti_kitSerialNumber,
            UT_DeviceId: item.uti_userTerminalId,
            ServiceLineNumber: item.sli_serviceLineNumber,
            DownlinkThroughput: item.ut_DownlinkThroughput,
            UplinkThroughput: item.ut_UplinkThroughput,
            isPublic: item.sli_publicIp == 0 ? 'No' : 'Yes',
            InternetPingLatencyMs: item.ut_PingLatencyMsAvg,
            R_Clients: item.r_Clients,
            R_WanTxBytes: item.r_WanTxBytes || 0,
            R_WanRxBytes: item.r_WanRxBytes || 0,
            Uptime: formatUptime(item.ut_Uptime),
            SignalQuality: item.ut_SignalQuality,
            isOnline: checkOnlineStatus(item.ut_UtcTimestampNs),
            isAlert: item.ut_ActiveAlert !== null && item.ut_ActiveAlert.length > 2 && item.ut_ActiveAlert.slice(-1) == ']' ? true : false,
            ActiveAlert : item.ut_ActiveAlert,
            AccountName: item.ca_ClientName,
            AccountNumber: item.ca_AccountNumber
          }));
          setRows(data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching router data:', error);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    fetchRouterData(); // Initial fetch

    const intervalId = setInterval(() => {
      fetchRouterData();
    }, 20000); // Fetch every 20 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [fetchRouterData]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Define DataGrid columns
  const columns = [
    
    {
      field: 'DeviceName',
      headerName: 'Nick Name',
      width: 230,
      headerClassName: 'MuiDataGrid-columnHeaders',
      renderCell: (params) => (
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Centra horizontalmente
              alignItems: 'center',     // Centra verticalmente
              height: '100%',           // Asegura que ocupe toda la altura de la celda
              width: '100%',            // Asegura que ocupe todo el ancho de la celda
            }}
          >
        <Button
          variant="outlined"
          color={params.row.isOnline ? 'success' : 'error'}
          onClick={() => navigate(`/dashboard`, { state: { deviceId: params.row.UT_DeviceId } })}
          sx={{
            width: '100%',
            whiteSpace: 'normal',  // Allows text to wrap
            wordWrap: 'break-word',  // Breaks long words to wrap properly
            textAlign: 'center', // Center the text if needed
          }}
        >
          {params.row.DeviceName}
          </Button>
        </Box>
      ),
    },
    { field: 'Kitname', headerName: 'Kit SerialNumber', width: 230,headerClassName: 'MuiDataGrid-columnHeaders' },
    { field: 'DownlinkThroughput', headerName: 'Downlink', width: 100,
      headerClassName: 'MuiDataGrid-columnHeaders',
      renderCell: (params) => params.value !== null ? `${params.value} Mbps` : ''

     },
    { field: 'UplinkThroughput', headerName: 'Uplink', width: 100,
      headerClassName: 'MuiDataGrid-columnHeaders',
      renderCell: (params) => params.value !== null ? `${params.value} Mbps` : ''

     },
    { field: 'InternetPingLatencyMs', headerName: 'Ping Latency', width: 100, 
      headerClassName: 'MuiDataGrid-columnHeaders',
      renderCell: (params) => params.value !== null ? `${params.value} ms` : ''

    },
    
    { field: 'R_Clients', headerName: 'Clients', width: 90,headerClassName: 'MuiDataGrid-columnHeaders' },
    {
      field: 'R_WanTxBytes',
      headerName: 'Tx Bytes',
      headerClassName: 'MuiDataGrid-columnHeaders',
      width: 100,
      valueFormatter: (value) => `${(value / (1024 * 1024 * 1024)).toFixed(2)} GB`,
    },
    {
      field: 'R_WanRxBytes',
      headerName: 'Rx Bytes',
      headerClassName: 'MuiDataGrid-columnHeaders',
      width: 100,
      valueFormatter: (value) => `${(value / (1024 * 1024 * 1024)).toFixed(2)} GB`,
    },
   
    {
      field: 'isAlert',
      headerName: 'Alert',
      headerClassName: 'MuiDataGrid-columnHeaders',
      width: 100,
      renderCell: (params) => (
        params.row.isAlert ? <NotificationImportantIcon /> : null
      ),
    },
    { field: 'isPublic', headerName: 'Public Ip', width: 90, headerClassName: 'MuiDataGrid-columnHeaders'

    },
    
    { field: 'AccountName', headerName: 'Account', width: 220, headerClassName: 'MuiDataGrid-columnHeaders' },

  ];

  return (
    <Box sx={{ height: '90vh', width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={10}  rowHeight={80} sx={{
                border: 0,
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark : theme.palette.light,
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.DataHeaders.dark : theme.palette.DataHeaders.light,
                },
              }}/>
    </Box>
  );
};

export default Routers;
