import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

// Import Recharts components
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

const DashboardBilling = () => {
  const location = useLocation();

  const initialServiceLineNumber = location.state?.serviceLineNumber || '';

  const [devices, setDevices] = useState([]);
  const [billingCycles, setBillingCycles] = useState([]);
  const [selectedServiceLineNumber, setSelectedServiceLineNumber] = useState(
    initialServiceLineNumber
  );
  const [selectedBillingCycleId, setSelectedBillingCycleId] = useState('');
  const [dailyUsageData, setDailyUsageData] = useState([]);
  const [userTerminalData, setUserTerminalData] = useState(null);
  const [error, setError] = useState(null);

  // Separate loading states
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [loadingUserTerminal, setLoadingUserTerminal] = useState(false);
  const [loadingBillingCycles, setLoadingBillingCycles] = useState(false);
  const [loadingDailyUsage, setLoadingDailyUsage] = useState(false);

  const loading =
    loadingDevices ||
    loadingUserTerminal ||
    loadingBillingCycles ||
    loadingDailyUsage;

  // Pie chart data state
  const [pieChartData, setPieChartData] = useState(null);

  // Bar chart data state
  const [barChartData, setBarChartData] = useState([]);

  // Fetch devices on mount
  useEffect(() => {
    const fetchDevices = async () => {
      setLoadingDevices(true);
      try {
        const perfilId = localStorage.getItem('perfilId');
        const groupId = localStorage.getItem('groupId');
        const userId = localStorage.getItem('userId');

        let apiEndpoint;
        if (perfilId === '1' || perfilId === '2') {
          apiEndpoint =
            'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling';
        } else if (perfilId === '3') {
          apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling/group/${groupId}`;
        } else if (perfilId === '4') {
          apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling/user/${userId}`;
        }

        const response = await axios.get(apiEndpoint, {
          headers: { accept: 'application/json' },
        });

        setDevices(response.data);
        setLoadingDevices(false);
      } catch (error) {
        console.error('Error fetching devices:', error);
        setError('Failed to fetch devices.');
        setLoadingDevices(false);
      }
    };

    fetchDevices();
  }, []);

  // Fetch user terminal data when service line number is selected
  useEffect(() => {
    const fetchUserTerminalData = async () => {
      if (selectedServiceLineNumber) {
        setLoadingUserTerminal(true);
        try {
          const response = await axios.get(
            `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdBilling/userterminal/${selectedServiceLineNumber}`
          );
          setUserTerminalData(response.data[0]); // Assuming response is an array
          setLoadingUserTerminal(false);
        } catch (error) {
          console.error('Error fetching user terminal data:', error);
          setError('Failed to fetch user terminal data.');
          setLoadingUserTerminal(false);
        }
      }
    };

    fetchUserTerminalData();
  }, [selectedServiceLineNumber]);

  // Fetch billing cycles when service line number is selected
  useEffect(() => {
    const fetchBillingCycles = async () => {
      if (selectedServiceLineNumber) {
        setLoadingBillingCycles(true);
        try {
          const response = await axios.get(
            `https://echo.tsmpatagonia.com.ar:3001/api/billingCycles/${selectedServiceLineNumber}`
          );
          setBillingCycles(response.data);
          setLoadingBillingCycles(false);
        } catch (error) {
          console.error('Error fetching billing cycles:', error);
          setError('Failed to fetch billing cycles.');
          setLoadingBillingCycles(false);
        }
      }
    };

    fetchBillingCycles();
  }, [selectedServiceLineNumber]);

  // Fetch daily usage data when billing cycle is selected
  useEffect(() => {
    const fetchDailyUsageData = async () => {
      if (selectedBillingCycleId) {
        setLoadingDailyUsage(true);
        try {
          const response = await axios.get(
            `https://echo.tsmpatagonia.com.ar:3001/api/dailydatausage/${selectedBillingCycleId}`
          );
          setDailyUsageData(response.data);
          setLoadingDailyUsage(false);
        } catch (error) {
          console.error('Error fetching daily usage data:', error);
          setError('Failed to fetch daily usage data.');
          setLoadingDailyUsage(false);
        }
      }
    };

    fetchDailyUsageData();
  }, [selectedBillingCycleId]);

  // Compute pie chart data when billing cycle changes
  useEffect(() => {
    if (selectedBillingCycleId) {
      const selectedCycle = billingCycles.find(
        (cycle) => cycle.id === selectedBillingCycleId
      );
      if (selectedCycle) {
        const totalPriorityGB = parseFloat(selectedCycle.totalPriorityGB) || 0;
        const consumedAmountGB = parseFloat(selectedCycle.consumedAmountGB) || 0;
        const usageLimitGB = parseFloat(selectedCycle.usageLimitGB) || 0; // Assuming usageLimitGB is totalPriorityGB

        const remainingGB = Math.max(usageLimitGB - totalPriorityGB, 0);

        const data = [
          { label: 'Consumed GB', value: totalPriorityGB },
          { label: 'Remaining GB', value: remainingGB },
        ];

        setPieChartData(data);
      }
    } else {
      setPieChartData(null);
    }
  }, [selectedBillingCycleId, billingCycles]);

  // Prepare bar chart data when daily usage data changes
  useEffect(() => {
    if (dailyUsageData && dailyUsageData.length > 0) {
      setBarChartData(prepareBarChartData(dailyUsageData));
    } else {
      setBarChartData([]);
    }
  }, [dailyUsageData]);

  // Define colors for the pie chart
  const COLORS = ['#0088FE', '#FFBB28'];

  // Define days from 1 to 31
  const daysInMonth = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString()
  );

  // Function to prepare bar chart data for Recharts
  const prepareBarChartData = (dailyData) => {
    // Create a map from date to data
    const dataMap = dailyData.reduce((acc, data) => {
      const day = new Date(data.date).getDate().toString();
      acc[day] = data;
      return acc;
    }, {});

    // Generate data array
    return daysInMonth.map((day) => {
      const dataForDay = dataMap[day] || {};
      return {
        day,
        'Priority GB': parseFloat(dataForDay.priorityGB) || 0,
        'Opt-In Priority GB': parseFloat(dataForDay.optInPriorityGB) || 0,
        'Standard GB': parseFloat(dataForDay.standardGB) || 0,
        'Non-Billable GB': parseFloat(dataForDay.nonBillableGB) || 0,
      };
    });
  };

  return (
    <Box
      sx={{ height: '90vh', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {error && <Typography color="error">{error}</Typography>}

      <Grid container spacing={2} sx={{ flex: '1 1 auto', height: '100%' }}>
        {/* Left Panel */}
        <Grid item xs={2}>
          <Stack direction="column" spacing={2} sx={{ height: '100%' }}>
            {/* Service Line Number Selector */}
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="device-select-label">
                  Service Line Number
                </InputLabel>
                <Select
                  labelId="device-select-label"
                  id="device-select"
                  value={selectedServiceLineNumber}
                  label="Service Line Number"
                  onChange={(e) =>
                    setSelectedServiceLineNumber(e.target.value)
                  }
                >
                  {devices.map((device) => (
                    <MenuItem
                      key={device.serviceLineNumber}
                      value={device.serviceLineNumber}
                    >
                      {device.nickname} - {device.serviceLineNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Billing Cycle Selector */}
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="billing-cycle-select-label">
                  Billing Cycle
                </InputLabel>
                <Select
                  labelId="billing-cycle-select-label"
                  id="billing-cycle-select"
                  value={selectedBillingCycleId}
                  label="Billing Cycle"
                  onChange={(e) =>
                    setSelectedBillingCycleId(e.target.value)
                  }
                >
                  {billingCycles.map((cycle) => (
                    <MenuItem key={cycle.id} value={cycle.id}>
                      {`Cycle: ${new Date(
                        cycle.cycleStartDate
                      ).toLocaleDateString()} - ${new Date(
                        cycle.cycleEndDate
                      ).toLocaleDateString()}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Billing Cycle Details */}
            {selectedBillingCycleId && (
              <Grid item sx={{ flex: 1 }}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    height: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {billingCycles
                    .filter((cycle) => cycle.id === selectedBillingCycleId)
                    .map((cycle) => (
                      <Box key={cycle.id}>
                        <strong>
                          Cycle Start Date:{' '}
                        </strong>
                        <Typography variant="body1">
                          {new Date(cycle.cycleStartDate).toLocaleDateString()}
                        </Typography>
                        <strong>
                          Cycle End Date:{' '}
                        </strong>
                        <Typography variant="body1">
                          {new Date(cycle.cycleEndDate).toLocaleDateString()}
                        </Typography>
                        <strong>
                          Total Priority GB:{' '}
                        </strong>
                        <Typography variant="body1">
                          {cycle.totalPriorityGB}
                        </Typography>
                        <strong>Consumed GB:</strong>
                        <Typography variant="body1">
                          {cycle.consumedAmountGB}
                        </Typography>
                        <strong>Overage GB:</strong>
                        <Typography variant="body1">
                          {cycle.overageAmountGB}
                        </Typography>
                        <strong>Price Per GB:</strong>
                        <Typography variant="body1">
                          {cycle.pricePerGB}
                        </Typography>
                        <strong>Overage Price:</strong>
                        <Typography variant="body1">
                          {cycle.overagePrice}
                        </Typography>
                      </Box>
                    ))}
                </Paper>
              </Grid>
            )}

            
          </Stack>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={8}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : barChartData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width= "100%"
                height={400}
                 barCategoryGap="5%"
                data={barChartData}
                
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" interval={0}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Priority GB"  stackId = "a" fill="#1976d2" />
                <Bar dataKey="Opt-In Priority GB" stackId = "a" fill="#ff9800" />
                <Bar dataKey="Standard GB" stackId = "a"  fill="#4caf50" />
                <Bar dataKey="Non-Billable GB" stackId = "a" fill="#f44336" />
              </BarChart>
              </ResponsiveContainer>
            ) : (
              <Typography>Select a Billing Cycle to view data usage</Typography>
            )}
          </Paper>
        </Grid>
        {/* Pie Chart */}
        {pieChartData && (
          <Grid item xs={2}>
            <Paper
              elevation={2}
              sx={{
                 p: 2,
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              }}
            ><ResponsiveContainer>
              <PieChart width="100%" height={100}>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  nameKey="label"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  innerRadius={40}
                  fill="#8884d8"
                  label
                  border={0}

                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend verticalAlign="bottom" height={46} />
              </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* User Terminal Data */}
      {userTerminalData && (
        <Grid container spacing={2}>
          {/* Product Reference ID */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Product Reference ID</Typography>
              <Typography variant="body1">
                {userTerminalData.productReferenceId}
              </Typography>
            </Paper>
          </Grid>

          {/* Service Start Date */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Service Start Date</Typography>
              <Typography variant="body1">
                {new Date(
                  userTerminalData.serviceStartDate
                ).toLocaleDateString()}
              </Typography>
            </Paper>
          </Grid>

          {/* Service End Date */}
          {userTerminalData.serviceEndDate && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
                <Typography variant="h6">Service End Date</Typography>
                <Typography variant="body1">
                  {new Date(
                    userTerminalData.serviceEndDate
                  ).toLocaleDateString()}
                </Typography>
              </Paper>
            </Grid>
          )}

          {/* Public IP */}
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Public IP</Typography>
              <Typography variant="body1">
                {userTerminalData.publicIp === 1 ? 'Yes' : 'No'}
              </Typography>
            </Paper>
          </Grid>

          {/* Service Active */}
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Service Active</Typography>
              <Typography variant="body1">
                {userTerminalData.serviceActive === 1 ? 'Active' : 'Inactive'}
              </Typography>
            </Paper>
          </Grid>

          {/* Account Number */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Account Number</Typography>
              <Typography variant="body1">
                {userTerminalData.accountNumber}
              </Typography>
            </Paper>
          </Grid>

          {/* ISO Currency Code */}
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">ISO Currency Code</Typography>
              <Typography variant="body1">
                {userTerminalData.isoCurrencyCode}
              </Typography>
            </Paper>
          </Grid>

          {/* Is Mobile Plan */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Is Mobile Plan</Typography>
              <Typography variant="body1">
                {userTerminalData.isMobilePlan === 1 ? 'Yes' : 'No'}
              </Typography>
            </Paper>
          </Grid>

          {/* Overage Name */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Overage Name</Typography>
              <Typography variant="body1">
                {userTerminalData.overageName || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          {/* Overage Description */}
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Overage Description</Typography>
              <Typography variant="body1">
                {userTerminalData.overageDescription || 'N/A'}
              </Typography>
            </Paper>
          </Grid>

          {/* Is Opted Into Overage */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper elevation={2} sx={{ p: 2, height: '100px' }}>
              <Typography variant="h6">Is Opted Into Overage</Typography>
              <Typography variant="body1">
                {userTerminalData.isOptedIntoOverage === 1 ? 'Yes' : 'No'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DashboardBilling;
