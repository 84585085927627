import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Grid,
  Alert,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

const EditUser = () => {
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    nombre: '',
    lastName: '',
    email: '',
    password: '',
    perfil_id: '',
    grupo_id: '',
    avatar: null,
    logoDark: null,
    logoLight: null,
  });
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [logoDarkPreview, setLogoDarkPreview] = useState(null);
  const [logoLightPreview, setLogoLightPreview] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [imageErrors, setImageErrors] = useState({
    avatar: '',
    logoDark: '',
    logoLight: '',
  });
  const perfilId = localStorage.getItem('perfilId');
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setUsersLoading(true);
    try {
      const response = await axios.get('https://echo.tsmpatagonia.com.ar:3001/api/users', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (err) {
      setUsersError('Error loading users');
    } finally {
      setUsersLoading(false);
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setFormData({
      ...user,
      password: '', // Deja el campo de contraseña vacío inicialmente
      avatar: null, // Limpia el campo de avatar para nuevas cargas
      logoDark: null,
      logoLight: null,
    });

    

    if (user.avatar) {
      setAvatarPreview(`data:image/jpeg;base64,${user.avatar}`);
    } else {
      setAvatarPreview(null);
    }

    if (user.logoDark) {
      setLogoDarkPreview(`data:image/jpeg;base64,${user.logoDark}`);
    } else {
      setLogoDarkPreview(null);
    }

    if (user.logoLight) {
      setLogoLightPreview(`data:image/jpeg;base64,${user.logoLight}`);
    } else {
      setLogoLightPreview(null);
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(password);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      if (value && !validatePassword(value)) {
        setPasswordError('Password must be at least 8 characters, include an uppercase letter and a number.');
      } else {
        setPasswordError('');
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e, imageType) => {
    const file = e.target.files[0];

    if (!file) {
      setFormData({ ...formData, [imageType]: null });
      return;
    }

    if (file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
      setImageErrors({ ...imageErrors, [imageType]: 'Only JPG files are allowed.' });
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 800;
        const maxHeight = 800;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const resizedImage = canvas.toDataURL('image/jpeg', 0.8);
        const base64String = resizedImage.split(',')[1];

        setFormData({ ...formData, [imageType]: base64String });

        if (imageType === 'avatar') {
          setAvatarPreview(resizedImage);
        } else if (imageType === 'logoDark') {
          setLogoDarkPreview(resizedImage);
        } else if (imageType === 'logoLight') {
          setLogoLightPreview(resizedImage);
        }

        setImageErrors({ ...imageErrors, [imageType]: '' });
      };
    };

    reader.readAsDataURL(file);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axios.put(`https://echo.tsmpatagonia.com.ar:3001/api/users`, {
        id: formData.id,
        nombre: formData.nombre,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        perfil_id: formData.perfil_id,
        grupo_id: formData.grupo_id,
        avatar: formData.avatar,
        logoDark: formData.logoDark,
        logoLight: formData.logoLight,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSuccess('Profile updated successfully');
      fetchUsers(); // Actualiza la lista de usuarios
    } catch (err) {
      setError(`Error updating profile: ${err.response?.data?.error || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID'},
    { field: 'nombre', headerName: 'Name', flex: 1},
    { field: 'lastName', headerName: 'Last Name', flex: 1},
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'perfil_id', headerName: 'Perfil ID'},
    { field: 'grupo_id', headerName: 'Grupo ID' },
    {
      field: 'actions',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleEditClick(params.row)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 3 }}>
      {usersError && <Alert severity="error">{usersError}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {Object.values(imageErrors).some((error) => error) &&
        Object.entries(imageErrors).map(([key, value]) =>
          value ? <Alert severity="error" key={key}>{value}</Alert> : null
        )
      }

      {usersLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ height: 400, width: '100%', marginBottom: 20 }}>
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.id}
            sx={{
                border: 0,
                backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark : theme.palette.light,
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.DataHeaders.dark : theme.palette.DataHeaders.light,
                },
              }}
          />
        </div>
        
      )}
      {selectedUser && (
        <Paper elevation={2} sx={{ p: 2 }}>
          <Typography variant="h6">User: {selectedUser.nombre} {selectedUser.lastName}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} align="center">
              <Typography variant="subtitle1" gutterBottom>
                Avatar
              </Typography>
              
              {avatarPreview ? (
                <Avatar src={avatarPreview} alt="Avatar" sx={{ width: 100, height: 100, margin: 'auto' }} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, margin: 'auto' }} />
              )}
              <Button component="label" sx={{ mt: 2 }} variant="contained">
                Change Avatar (Only JPG)
                <input type="file" hidden onChange={(e) => handleFileChange(e, 'avatar')} accept="image/jpeg" />
              </Button>

              <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                Dark Logo
              </Typography>
              {logoDarkPreview ? (
                <Avatar src={logoDarkPreview} alt="Logo Oscuro" sx={{ width: 100, height: 100, margin: 'auto' }} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, margin: 'auto' }} />
              )}
              <Button component="label" sx={{ mt: 2 }} variant="contained">
                Select (Only JPG)
                <input type="file" hidden onChange={(e) => handleFileChange(e, 'logoDark')} accept="image/jpeg" />
              </Button>

              <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                Light Logo 
              </Typography>
              {logoLightPreview ? (
                <Avatar src={logoLightPreview} alt="Logo Claro" sx={{ width: 100, height: 100, margin: 'auto' }} />
              ) : (
                <Avatar sx={{ width: 100, height: 100, margin: 'auto' }} />
              )}
              <Button component="label" sx={{ mt: 2 }} variant="contained">
                Select (Only JPG)
                <input type="file" hidden onChange={(e) => handleFileChange(e, 'logoLight')} accept="image/jpeg" />
              </Button>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box component="form" onSubmit={handleFormSubmit}>
                <TextField fullWidth margin="normal" label="Email" name="email" value={formData.email} onChange={handleInputChange} required />
                <TextField fullWidth margin="normal" label="Name" name="nombre" value={formData.nombre} onChange={handleInputChange} required />
                <TextField fullWidth margin="normal" label="Last Name" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                <TextField fullWidth margin="normal" label="New Password" name="password" type="password" value={formData.password} onChange={handleInputChange} helperText={passwordError} error={!!passwordError} />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="perfil-label">Perfil ID</InputLabel>
                  <Select
                    labelId="perfil-label"
                    name="perfil_id"
                    value={formData.perfil_id}
                    onChange={handleInputChange}
                    label="Perfil ID"
                  >
                    {(perfilId === '1' || perfilId === '2') && (
                        <>
                            <MenuItem value={1}>SupAdmin</MenuItem>
                            <MenuItem value={2}>Admin</MenuItem>
                        </>
                    )}
                    <MenuItem value={3}>Partner</MenuItem>
                    <MenuItem value={4}>Guest</MenuItem>
                  </Select>
                </FormControl>
                {(perfilId === '1' || perfilId === '2') && (
                        <>
                <TextField fullWidth margin="normal" label="Grupo ID" name="grupo_id" value={formData.grupo_id} onChange={handleInputChange} required />
                </>
                )}
                <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }} disabled={loading || !!passwordError}>
                  {loading ? <CircularProgress size={24} /> : 'Update Profile'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default EditUser;
