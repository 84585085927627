import React from 'react';
import { Typography } from '@mui/material';

const Settings = () => (
  <div>
    <Typography variant="h4" gutterBottom>
      Settings
    </Typography>
    <Typography variant="body1">User settings and preferences will be managed here.</Typography>
  </div>
);

export default Settings;
