import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import * as h3 from 'h3-js';
/* global BigInt */

mapboxgl.accessToken = 'pk.eyJ1IjoiZnJvY2NvYXIiLCJhIjoiY20wbXo4N3FvMDMwbzJrb21kOXhsY3FlbyJ9.chVdQIlwUlzcsAvuJsbx8g';

const Maps = () => {
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const [antennaData, setAntennaData] = useState([]);

  const defaultMapDark = 'mapbox://styles/froccoar/claa813s2001115ns0dfdfzzt';
  const defaultMapLight = 'mapbox://styles/froccoar/clzru7x0100fn01pbf08t4v1v';

  const mapStyle = theme.palette.mode === 'dark' ? defaultMapDark : defaultMapLight;

  const fetchAntennaData = async () => {
    const perfilId = localStorage.getItem('perfilId');
    const groupId = localStorage.getItem('groupId');
    const userId = localStorage.getItem('userId');
  
    let apiEndpoint;
  
    if (perfilId === '1' || perfilId === '2') {
      apiEndpoint = 'https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters';
    } else if (perfilId === '3') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/group/${groupId}`;
    } else if (perfilId === '4') {
      apiEndpoint = `https://echo.tsmpatagonia.com.ar:3001/api/stDeviceIdRouters/user/${userId}`;
    }

    if (userId) {
      try {
        const response = await axios.get(apiEndpoint);
        const data = response.data.map(router => {
          const isOnline = checkOnlineStatus(router.ut_UtcTimestampNs);
          return {
            ...router,
            isOnline,
          };
        });
        setAntennaData(data);
      } catch (error) {
        console.error('Error fetching router data:', error);
      }
    }
  };

  const checkOnlineStatus = (utcTimestampNs) => {
    const timestampMs = utcTimestampNs / 1e6;
    const buenosAiresTimeString = new Date(timestampMs).toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const buenosAiresDate = new Date(buenosAiresTimeString);
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false,
    });

    const currentDateObj = new Date(currentDate);
    const timeDifference = Math.abs(currentDateObj - buenosAiresDate);
    return timeDifference < 600000; // 10 minutes
  };

  useEffect(() => {
    fetchAntennaData();
    const interval = setInterval(() => {
      fetchAntennaData();
    }, 15000); // 15 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: mapStyle,
      center: [-67.55045749329654, -24.04252433841831],
      zoom: 5,
      pitch: 0,
      bearing: 0,
    });

    mapInstance.addControl(new mapboxgl.NavigationControl());

    mapInstance.on('style.load', () => {
      // Agregar datos solo después de que el estilo esté completamente cargado
      antennaData.forEach(antenna => {
        if (!antenna.ut_H3CellId) {
          console.warn('ut_H3CellId es null o undefined para la antena:', antenna);
          return;
        }

        const H3CellIdHex = BigInt(antenna.ut_H3CellId).toString(16);
        if (!h3.isValidCell(H3CellIdHex)) {
          console.error('H3CellIdHex no es un índice H3 válido:', H3CellIdHex);
          return;
        }

        const hexBoundary = h3.cellToBoundary(H3CellIdHex);
        const geoJsonPolygon = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [hexBoundary.map(coord => [coord[1], coord[0]])],
          },
          properties: {
            nickname: antenna.sli_nickname,
            serviceLineNumber: antenna.sli_serviceLineNumber,
            ClientName: antenna.ca_ClientName,
            isOnline: antenna.isOnline,
          },
        };

        if (mapInstance.getSource(`antenna-${antenna.uti_userTerminalId}`)) {
          mapInstance.getSource(`antenna-${antenna.uti_userTerminalId}`).setData(geoJsonPolygon);
        } else {
          mapInstance.addSource(`antenna-${antenna.uti_userTerminalId}`, {
            type: 'geojson',
            data: geoJsonPolygon,
          });

          mapInstance.addLayer({
            id: `antenna-layer-${antenna.uti_userTerminalId}`,
            type: 'fill',
            source: `antenna-${antenna.uti_userTerminalId}`,
            layout: {},
            paint: {
              'fill-color': ['case', ['==', ['get', 'isOnline'], true], '#00FF00', '#FF0000'],
              'fill-opacity': 0.8,
            },
          });

          mapInstance.on('click', `antenna-layer-${antenna.uti_userTerminalId}`, (e) => {
            const coordinates = e.lngLat;
            const nickname = e.features[0].properties.nickname;
            const serviceLineNumber = e.features[0].properties.serviceLineNumber;
            const ClientName = e.features[0].properties.ClientName;
            
            const popupHTML = `
              <div style="
                    color: black;
                    font-size: 12px;
                    padding: 10px;
                    width: 200px;
                    border-radius: 8px;
                    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);">
                <strong>Nickname:</strong> ${nickname}<br />
                <strong>Service Line Number:</strong> ${serviceLineNumber}<br />
                <strong>Account: </strong> ${ClientName}
              </div>
            `;

            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(popupHTML)
              .addTo(mapInstance);
          });
        }
      });
    });

    setMap(mapInstance);

    return () => mapInstance.remove();
  }, [mapStyle, antennaData]);

  return <div id="map" style={{ width: '100%', height: '90vh' }} />;
};

export default Maps;
